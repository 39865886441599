.signup-message {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .signup-message a {
    color: #007bff; /* Change link color */
    text-decoration: none;
  }
  
  .signup-message a:hover {
    text-decoration: underline;
  }
  .error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
    