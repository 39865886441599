/* Container for the user's articles page */
.user-articles-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Page headings */
  .user-articles-container h1 {
    font-size: 28px;
    color: #333;
    text-align: center;
  }
  
  .user-articles-container h2 {
    font-size: 24px;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* No articles message */
  .no-articles {
    font-size: 18px;
    color: #777;
    text-align: center;
    margin-top: 40px;
  }
  
  /* Error message */
  .error-message {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Articles list */
  .articles-list {
    list-style-type: none;
    padding: 0;
  }
  
  .article-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
  }
  
  .article-item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Article title */
  .article-title {
    font-size: 20px;
    color: #007bff;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  /* Article snippet */
  .article-snippet {
    font-size: 16px;
    color: #555;
  }

  /* Create New Article Button */
.create-article-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0;
    background-color: #007bff;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
  }
  
  .create-article-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
    transform: translateY(-2px); /* Slight hover effect */
  }
  
  .create-article-button:active {
    background-color: #004494; /* Further darken on click */
    transform: translateY(0); /* Return to normal on click */
  }
  