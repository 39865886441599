/* ArticleEditPage.css */

.article-edit-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-edit-container h2 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .article-edit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .article-edit-form label {
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }
  
  .article-edit-form input[type="text"] {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    transition: border-color 0.3s ease;
  }
  
  .article-edit-form input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .article-edit-form .editor-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 200px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .article-edit-form .editor-container .ql-editor {
    min-height: 200px;
    font-size: 16px;
    color: #333;
  }
  
  .article-edit-form .submit-button {
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .article-edit-form .submit-button:hover {
    background-color: #0056b3;
  }
  
  .article-edit-form .submit-button:focus {
    outline: none;
  }
  
  .article-edit-form .success-message {
    font-size: 16px;
    color: green;
    text-align: center;
    margin-top: 10px;
  }
  
  .article-edit-form .error-message {
    font-size: 16px;
    color: red;
    text-align: center;
    margin-top: 10px;
  }

  .delete-button {
    background-color: #ff4d4d; /* Red background for a warning effect */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #e60000; /* Darker red on hover */
  }
  
  .delete-button:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5);
  }
  
  .delete-article-container {
    display: flex;
    justify-content: flex-end; /* Aligns the delete button to the right */
    margin-top: 20px;
  }
  
  .delete-article-container .delete-button {
    margin-left: 20px; /* Space between delete and any other buttons */
  }
  
  @media (max-width: 768px) {
    .article-edit-container {
      padding: 15px;
    }
  
    .article-edit-form .submit-button {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
  